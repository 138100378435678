import React from 'react';
import { Instagram, Twitter, Linkedin, Share2 } from 'lucide-react';

function Content({ projectBasket }) {
  const url = window.location.href;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Brogrammers blog',
          text: 'hello',
          url: url,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <nav className="w-full md:w-1/4 md:mr-4 mb-4 h-[600px]">
      <div className="bg-muted rounded-xl p-4 flex flex-col justify-between">
        <div>
          <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
          <ul className="list-none p-0">
            {projectBasket?.projects?.map((project, index) => (
              <li key={index} className="mb-2">
                <a
                  href={`#project-${index}`}
                  className="text-blue-500 hover:underline">
                  {project.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-center mt-4">
        <h3 className="text-lg font-semibold mb-2">Share</h3>
        <div className="flex space-x-4 mb-4">
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-700 transition">
            <Instagram className="w-6 h-6" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 transition">
            <Twitter className="w-6 h-6" />
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 transition">
            <Linkedin className="w-6 h-6" />
          </a>
        </div>
        <button
          onClick={handleShare}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition">
          <Share2 className="w-5 h-5 mr-2" />
          Share
        </button>
      </div>
    </nav>
  );
}

export default Content;
