import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { BrowserRouter } from 'react-router-dom';
import { PublicRoutes } from './pages/Pages';

import SignIn from './pages/SignIn';
import Layout from './layouts/layout';
import Pages from './pages/Pages';

const PUBLISHABLE_KEY = 'pk_live_Y2xlcmsuYnJvZ3JhbW1lcnMuaW4k'; // 'pk_test_cmlnaHQtZ2F0b3ItMTIuY2xlcmsuYWNjb3VudHMuZGV2JA'; //

function App() {
  return (
    <div className="App">
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <BrowserRouter>
          {/* Protected routes */}
          <SignedIn>
            <Layout>
              <Pages />
            </Layout>
          </SignedIn>
          {/* Public routes */}
          <PublicRoutes />
          {/* Welcome route */}
          <SignedOut>
            <SignIn />
          </SignedOut>
        </BrowserRouter>
      </ClerkProvider>
    </div>
  );
}

export default App;
