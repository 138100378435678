import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb';

export default function BreadcrumbDemo() {
  const location = useLocation();
  let currentLink = '';

  const pathnames = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '');

  return (
    <Breadcrumb className="overflow-hidden w-full">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} href="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        {pathnames.length > 0 && (
          <BreadcrumbSeparator>
            <ChevronRight />
          </BreadcrumbSeparator>
        )}
        {pathnames.map((crumb, index) => {
          currentLink += `/${crumb}`;
          const title = decodeURIComponent(crumb);
          const isLast = index === pathnames.length - 1;
          return (
            <React.Fragment key={currentLink}>
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage>
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink as={Link} href={currentLink}>
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {!isLast && (
                <BreadcrumbSeparator>
                  <ChevronRight />
                </BreadcrumbSeparator>
              )}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
