import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from './ui/card';
import { NotebookPen, Sparkles, Boxes, ChevronsRight } from 'lucide-react';
import { Button } from './ui/button';
import { Link } from 'react-router-dom';
import { timeAgo } from '../services';
import { useCourse } from '../context/useCourseContext';

function CourseCard({ course, viewed }) {
  const lastSeen = timeAgo(viewed);
  const { setCourse, clearSelectedTopic, setSelectedToggle } = useCourse();

  return (
    <Card className="bg-muted/40 shadow-lg h-full">
      <CardHeader>
        <CardTitle className="truncate pb-1 flex items-center justify-start">
          {course?.title}
        </CardTitle>
        {viewed && <CardDescription> Viewed {lastSeen}</CardDescription>}
        <CardContent className="p-0">
          <Button
            className="w-full mt-1 justify-start"
            onClick={() => {
              setCourse(course);
              clearSelectedTopic();
              setSelectedToggle('handwritten');
            }}
            asChild>
            <Link to={`/courses/${course?.title}?id=${course?._id}`}>
              <NotebookPen size={20} className="mr-2" />
              Handwritten Notes
            </Link>
          </Button>
          <Button
            className="w-full mt-1 justify-start"
            onClick={() => {
              setCourse(course);
              clearSelectedTopic();
              setSelectedToggle('practice');
            }}
            asChild>
            <Link to={`/courses/${course?.title}?id=${course?._id}`}>
              <Boxes size={20} className="mr-2" />
              Questions
            </Link>
          </Button>
          <Button
            className="w-full mt-1 justify-start"
            onClick={() => {
              setCourse(course);
              clearSelectedTopic();
              setSelectedToggle('summary');
            }}
            asChild>
            <Link to={`/courses/${course?.title}?id=${course?._id}`}>
              <Sparkles size={20} className="mr-2" />
              Glossary
            </Link>
          </Button>
        </CardContent>
        <CardFooter className="px-0">
          <Button
            variant="outline"
            className="w-full mt-1 justify-start bg-brand text-black hover:bg-yellow-700"
            onClick={() => {
              clearSelectedTopic();
              setCourse(course);
            }}
            asChild>
            <Link
              className="flex justify-between items-center"
              to={`/courses/${course?.title}?id=${course?._id}`}>
              <h3>Explore</h3>
              <ChevronsRight />
            </Link>
          </Button>
        </CardFooter>
      </CardHeader>
    </Card>
  );
}

export default CourseCard;
