import React from 'react';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Label } from '../../components/ui/label';
import { ScrollArea } from '../../components/ui/scroll-area';
import { AvatarComponent } from '../../components/readBlog';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '../../components/ui/card';
import Footer from './Footer';

function Help({ isStandalone }) {
  const [result, setResult] = React.useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult('Sending....');
    const formData = new FormData(event.target);

    formData.append('access_key', 'e6cbd6b1-19b6-4068-97e9-52731db468e5');

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult('Form Submitted Successfully');
      event.target.reset();
    } else {
      console.log('Error', data);
      setResult(data.message);
    }
  };

  return (
    <ScrollArea className={`${isStandalone ? 'h-screen' : 'h-full'}`}>
      <div className="p-2 md:container md:p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl md:text-8xl font-bold pb-10">
            Help & Support
          </h1>

          <section className="mb-12">
            <div className="p-3 rounded-lg border-dashed border-2 bg-muted/40 mb-8">
              <div className="flex items-center space-x-4 mb-4">
                <AvatarComponent src="/path/to/avatar.jpg" alt="Vinit Jain" />
                <div>
                  <p className="text-lg font-semibold">Hey, I’m Vinit Jain.</p>
                  <p className="text-sm text-primary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Autem, nulla?
                  </p>
                </div>
              </div>
              <div className="flex space-x-2 mt-2">
                {/* <Button href="">Buy Me a Coffee</Button> */}
                <Button href="https://github.com/yourusername">GitHub</Button>
                <Button href="https://linkedin.com/in/">LinkedIn</Button>
                <Button href="https://youtube.com/yourusername">YouTube</Button>
              </div>
            </div>

            <h2 className="text-2xl font-semibold mb-4">
              Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold">How do I create a course?</h3>
                <p>
                  To create a course, click on the 'Create' button on the bottom
                  left corner and fill in the topic and time you have.
                </p>
              </div>
              <div>
                <h3 className="font-semibold">How can I reset my password?</h3>
                <p>
                  If you've forgotten your password, click on 'Forgot Password'
                  on the login page and follow the instructions.
                </p>
              </div>
              <div>
                <h3 className="font-semibold">How do I contact support?</h3>
                <p>
                  You can contact our support team by filling out the contact
                  form below or emailing us at support@example.com.
                </p>
              </div>
            </div>
          </section>

          <section className="mb-12 w-full">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <form className="space-y-4 w-full" onSubmit={onSubmit}>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  className={`p-2 border rounded-md`}
                />
              </div>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  className={`p-2 border rounded-md`}
                />
              </div>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="message">Message</Label>
                <Textarea
                  name="message"
                  className={`p-2 border rounded-md`}
                  rows="4"
                />
              </div>
              <Button type="submit" className="w-full mt-4">
                Send Message
              </Button>
              <span>{result}</span>
            </form>
          </section>
        </div>
      </div>

      <Footer />
    </ScrollArea>
  );
}

export default Help;
