import React from 'react';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/icon.jpg';
import '../../styles/style.css';

function Header() {
  const location = useLocation();
  const originalRequestUrl = location.pathname + location.search;

  return (
    <section className="my-3 bg-gray-100 px-2 py-1 border-2 rounded-full flex justify-between items-center">
      <div className="flex items-center justify-start gap-2 pl-2">
        <Link to="/">
          <img
            src={logo}
            style={{ height: '38px', borderRadius: 10 }}
            alt="logo"
          />
        </Link>
        <h2>
          <Link to="/" className="text-xl font-semibold chakra-petch-bold">
            Brogrammers
          </Link>
        </h2>
      </div>

      <div className="hidden md:flex space-x-8 py-2 px-4">
        {['What we do', 'Pricing', 'How it works', 'Faq'].map((text) => (
          <a
            key={text}
            className="text-lg font-medium hover:text-gray-600 transition custom-font"
            href={`#${text.replace(/ /g, '-').toLowerCase()}`}>
            {text}
          </a>
        ))}
      </div>

      <div className="hidden md:flex flex-row gap-1 md:gap-2">
        <SignUpButton
          mode="modal"
          fallbackRedirectUrl={originalRequestUrl}
          className="w-auto text-sm px-2 sm:px-4 py-2 bg-black text-white rounded-full hover:bg-zinc-700 font-semibold transition">
          Get Started
        </SignUpButton>
        <SignInButton
          mode="modal"
          fallbackRedirectUrl={originalRequestUrl}
          className="w-auto text-sm px-2 sm:px-4 py-1 rounded-full bg-white hover:bg-gray-300 font-semibold transition">
          Sign In
        </SignInButton>
      </div>
    </section>
  );
}

export default Header;
