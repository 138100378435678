import React, { useEffect, useState } from 'react';
import Axios from '../../services/axios';
import { useCourse } from '../../context/useCourseContext';
import MarkDownView from '../MarkDownView';
import { Separator } from '../ui/separator';
import { Loading } from './Loading';
import YoutubeVids from './YoutubeVids';

function Topic({ topic }) {
  const { course, expanded } = useCourse();
  const [content, setContent] = useState('');
  const [videoIds, setVideoIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const getTopic = () => {
    setLoading(true);
    setContent('Please reload');
    Axios.get(
      `/course/topic/${course._id}/${encodeURIComponent(
        course.title
      )}/${encodeURIComponent(topic)}`
    )
      .then((res) => {
        setContent(res.data.data.markdownText);
        setVideoIds(
          res.data.data.suggestedVideos.map((link) => link.split('/').pop())
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTopic();
    // eslint-disable-next-line
  }, [topic]);

  return (
    <div className="flex-1 mx-2 overflow-hidden">
      <h1 className="font-bold text-lg pb-2 text-center">{topic}</h1>
      <Separator />
      {loading ? (
        <Loading />
      ) : (
        <div className="mb-10">
          <div className="flex flex-col md:flex-row md:space-x-6 mb-5">
            <div className="flex-1 overflow-auto">
              <MarkDownView>{content}</MarkDownView>
            </div>
            <div className={`mt-3 md:w-1/3 ${expanded && 'hidden'}`}>
              <h1 className="mb-2 font-bold text-lg">Suggested Videos:</h1>
              <div className="grid grid-cols-1 gap-4">
                <YoutubeVids videoIds={videoIds} />
              </div>
            </div>
          </div>
          {expanded && (
            <div className="mt-5">
              <h1 className="mb-2 font-bold text-lg">Suggested Videos:</h1>
              <div
                id="vids"
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-10">
                <YoutubeVids videoIds={videoIds} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Topic;
