import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader } from '../ui/card';
import { Heart, Bookmark, BookmarkCheck } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Badge } from '../ui/badge';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import { axios, timeAgo } from '../../services';
import { useUser } from '@clerk/clerk-react';
import { usePlacementContext } from '../placement';
import ms from '../../assets/comapnies/5.webp';

function getRandomBgClass() {
  const colors = [
    'bg-gradient-to-r from-cyan-200/10 to-cyan-600', // Bright cyan
    'bg-gradient-to-r from-rose-200/10 to-rose-600', // Bright rose
    'bg-gradient-to-r from-teal-200/10 to-teal-600', // Bright teal
    'bg-gradient-to-r from-sky-200/10 to-sky-600', // Bright sky blue
    'bg-gradient-to-r from-fuchsia-200/10 to-fuchsia-600', // Bright fuchsia
    'bg-gradient-to-r from-violet-200/10 to-violet-600', // Bright violet
    'bg-gradient-to-r from-emerald-200/10 to-emerald-600', // Bright Emerald
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}

function BlogCard({ blog }) {
  const { user } = useUser();
  const { updateBookmark } = usePlacementContext();

  const [liked, setLiked] = useState(
    blog?.likes?.some((like) => like?.userId === user.id)
  );
  const [likesCount, setLikesCount] = useState(blog?.likes?.length);

  const onBookmark = async () => {
    try {
      await axios.patch(`/user/bookmark/${user?.id}/${blog?._id}`);
      updateBookmark(blog?._id, !blog.bookmarked, blog);
    } catch (error) {
      console.log('error updating bookmark: ', error);
    }
  };

  const onLike = async () => {
    try {
      const newLikedStatus = !liked;
      await axios.patch(`/placement/${user.id}/${blog._id}`);
      setLiked(newLikedStatus);
      setLikesCount((prevCount) => prevCount + (newLikedStatus ? 1 : -1));
    } catch (error) {
      console.log('error updating like: ', error);
    }
  };

  const bottomBgClass = getRandomBgClass();

  return (
    <Card className="relative shadow-lg rounded-lg overflow-hidden w-full sm:max-w-[310px] flex flex-col bg-gray-500">
      <div className="relative">
        <div
          className="absolute inset-0 bg-cover bg-center blur-md"
          style={{ backgroundImage: `url(${ms})` }}
        />
        <div className={`absolute inset-0 ${bottomBgClass}`} />
        <div className="relative bg-gradient-to-r from-gray-600/20 to-black/70 p-4">
          <Link
            to={`/blogs/${blog._id}`}
            state={{ blog }}
            className="flex flex-col h-full">
            <CardHeader className="border-b border-gray-200">
              <div className="flex flex-row justify-start gap-5 items-end h-[120px]">
                <Avatar>
                  <AvatarImage
                    src={
                      blog?.userId?.imageUrl
                        ? blog.userId.imageUrl
                        : 'https://github.com/shadcn.png'
                    }
                  />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>

                <h2 className="text-xl font-semibold text-white">
                  {blog?.studentName}
                  <p className="text-sm">{timeAgo(blog?.submissionDate)}</p>
                </h2>
              </div>

              <Button
                size="sm"
                variant={blog.bookmarked ? 'solid' : 'outline'}
                className={`relative sm:absolute sm:top-4 sm:right-4 ${
                  blog.bookmarked
                    ? 'bg-blue-500 border-transparent'
                    : 'border-gray-300 '
                } hover:bg-pink-600 hover:border-pink-600 hover:text-white transition-all duration-200`}
                onClick={onBookmark}>
                {blog.bookmarked ? <BookmarkCheck /> : <Bookmark />}
              </Button>
            </CardHeader>
          </Link>
        </div>
      </div>
      <CardContent className="space-y-2 py-3 px-5 bg-gray-200 text-black blur:sm h-1/2">
        <div className="flex justify-between items-center">
          <Badge className="p-2 text-black" variant="outline">
            {blog?.companyName}
          </Badge>
          <Badge className="p-2" variant="secondary">
            {blog?.jobRole}
          </Badge>
        </div>
        <p className="text-lg font-bold px-2">{blog?.package} LPA</p>
        <div className="text-sm px-2">
          <p>Chandigarh University</p>
          <p className="mt-1">• {blog?.academicBackground}</p>
          <p>• CGPA: {blog?.cgpa}</p>
        </div>
      </CardContent>
      <CardFooter className={`flex justify-between items-center p-3`}>
        <Button
          variant="ghost"
          className="flex items-center space-x-1 hover:bg-muted/20"
          onClick={onLike}>
          <Heart
            className={`w-5 h-5 ${liked ? 'fill-current text-red-500' : ''}`}
          />
          <span>{likesCount}</span>
        </Button>
        <Link
          to={`/blogs/${blog._id}`}
          state={{ blog }}
          className="text-sm font-medium text-primary hover:underline">
          Read More
        </Link>
      </CardFooter>
    </Card>
  );
}

export default BlogCard;
