import React, { useMemo } from 'react';
import { RecentlyViewed, AdminCourses, CTA } from '../components/home';
import { ScrollArea } from '../components/ui/scroll-area';

const Home = () => {
  const recentlyViewedComponent = useMemo(() => <RecentlyViewed />, []);
  const myCoursesComponent = useMemo(() => <AdminCourses />, []);

  return (
    <ScrollArea className="h-full">
      <div className="container px-1 sm:px-7 py-1 mb-3">
        {recentlyViewedComponent}
        <CTA />
        {myCoursesComponent}
      </div>
    </ScrollArea>
  );
};

export default React.memo(Home);
